<template>
  <div>
    <!--    <div class="container-fluid insta-feed text-center px-0 mx-0" v-if="posts">-->
    <!--      <div id="footer-instagram" class="footer-insta slick-initialized slick-slider">-->

    <!--        <VueSlickCarousel v-bind="settings" style="height: 400px; overflow: hidden;">-->
    <!--          <div v-for="(post,index) in posts"-->
    <!--               :key="'post_thumb_'+post.id" style="width: 400px; height: 400px; overflow: hidden;">-->
    <!--            <div class="single-instagram"-->
    <!--                 :style="'width: 400px; height: 400px; overflow: hidden; background: url(' + $globalFunctions.getOptimizedImage(post.photos[0]) + ') center no-repeat; background-size: cover;'">-->
    <!--              <div class="insta-overlay" style="height: 400px; overflow: hidden;">-->
    <!--                <div class="insta-meta">-->
    <!--                  <h4 class="text-white">{{ post.title }}</h4>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <a @click.stop="-->
    <!--                        () => {-->
    <!--                          $router.push({-->
    <!--                            name: 'Post',-->
    <!--                            params: { postId: post.id }-->
    <!--                          });-->
    <!--                        }" class="insta-link" :tabindex="index" style="height: 400px; overflow: hidden;"></a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </VueSlickCarousel>-->

    <!--      </div>-->
    <!--    </div>-->

    <footer>
      <div class="container py-2">
        <div class="footer-widget-row">
          <div class="footer-widget contact-widget">
            <h2 class="widget-title text-uppercase"><br />Co-funders</h2>

            <div class="widget-title">
              <img
                :src="require('@/assets/images/mju_logo.png')"
                alt=""
                class="img-responsive"
                style="opacity: 0.8;"
              />
            </div>
            <div class="widget-title">
              <img
                :src="require('@/assets/images/pina_logo.png')"
                alt=""
                class="img-responsive"
                style="opacity: 0.8;"
              />
            </div>
            <div class="widget-title mt-5">
              <img
                :src="require('@/assets/images/iskra_logo.png')"
                alt=""
                class="img-responsive"
                style="opacity: 0.8;"
              />
            </div>
          </div>
          <div class="footer-widget twitter-widget">
            <h2 class="widget-title text-uppercase mb-3">
              Lead partner and <br />
              Application Manager
            </h2>
            <div class="single-tweet">
              <div>
                <a
                  href="https://www.dobra-pot.si"
                  target="_blank"
                  class="text-white"
                  >Zavod Dobra pot</a
                >
              </div>
              <div class="mt-1">
                <a href="mailto:info@dobra-pot.si" class="text-white"
                  >info@dobra-pot.si</a
                >
              </div>
            </div>

            <h2 class="widget-title text-uppercase mt-5 mb-3">
              Project partners
            </h2>
            <div class="single-tweet">
              <a
                href="https://www.park-skocjanske-jame.si"
                target="_blank"
                class="text-white"
                >Škocjan caves Park</a
              >
            </div>
            <div class="single-tweet">
              <a
                href="https://www.etno-muzej.si"
                target="_blank"
                class="text-white"
                >Slovenian Ethnographic Museum</a
              >
            </div>
          </div>
          <!--<div class="footer-widget social-widget">
            <h2 class="widget-title text-uppercase">
              Keep in touch
            </h2>
            <ul>
              <li><a href=""><i class="fa fa-facebook"></i>facebook</a></li>
              <li><a href=""><i class="fa fa-twitter"></i>twitter</a></li>
              <li><a href=""><i class="fa fa-instagram"></i>instagram</a></li>
              <li><a href=""><i class="fa fa-google-plus"></i> google plus</a></li>
              <li><a href=""><i class="fa fa-youtube"></i>Youtube</a></li>
              <li><a href=""><i class="fa fa-envelope"></i> E-mail</a></li>
              <li><a href=""><i class="fa fa-vimeo"></i> Vimeo </a></li>
              <li><a href=""><i class="fa fa-pinterest"></i> Pinterest</a></li>

            </ul>
          </div>-->
          <div class="footer-widget testimonial-widget">
            <b-carousel
              id="myCarousel"
              v-model="slide"
              :interval="10000"
              style="height: 400px"
              indicators
            >
              <!-- Slides with custom text -->
              <b-carousel-slide style="height: 370px;">
                <div class="single-review">
                  <router-link
                    :to="{ name: 'EN_About', hash: '#javni-park-skocjanske-jame' }"
                  >
                    <div class="review-text">
                      <p style="line-height: 22px;">
                        Memory is at the very heart of the traditions of both the individual and the
                        of one community or another. Moreover, historical memory
                        is equated with a tradition or heritage which, from time to time
                        present of an individual or a community is linked to
                        previous periods.
                      </p>
                    </div>
                    <div class="author-id">
                      <div class="author-text">
                        <h4 class="text-left pl-2">Škocjan caves Park</h4>
                      </div>
                    </div>
                  </router-link>
                </div>
              </b-carousel-slide>

              <!-- Slides with image only -->
              <b-carousel-slide style="height: 370px;">
                <div class="single-review">
                  <router-link
                    :to="{
                      name: 'EN_About',
                      hash: '#slovenski-etnografski-muzej'
                    }"
                  >
                    <div class="review-text">
                      <p style="line-height: 22px;">
                        The Slovenian Ethnographic Museum supports the project's aim, which is to
                        to strengthen the inclusion of older people in society - as important
                        as important carriers of knowledge and skills, as bearers of living traditions.
                      </p>
                    </div>
                    <div class="author-id">
                      <div class="author-text">
                        <h4 class="text-left pl-2">
                          Slovenian Ethnographic Museum
                        </h4>
                      </div>
                    </div>
                  </router-link>
                </div>
              </b-carousel-slide>

              <!-- Slides with image only -->
              <b-carousel-slide style="height: 370px;">
                <div class="single-review">
                  <router-link
                    :to="{ name: 'EN_About', hash: '#zavod-dobra-pot' }"
                  >
                    <div class="review-text">
                      <p style="line-height: 22px;">
                        The Zapisi spomina app is for people. For
                        to show the value of oral tradition and to
                        recalling, recounting and recording memories
                        strengthen intergenerational links between people.
                      </p>
                    </div>
                    <div class="author-id">
                      <div class="author-text">
                        <h4 class="text-left pl-2">Dobra pot Institute</h4>
                      </div>
                    </div>
                  </router-link>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
      <div class="container-fluid text-center ft-copyright py-2">
        <p>
          © {{ moment().format("YYYY") }}
          <a href="https://www.dobra-pot.si" target="_blank"
            >Dobra pot Institute
          </a>
          <span class="px-3">|</span>
          <router-link
            :to="{ name: 'EN_TermsAndConditions' }"
            active-class="active"
            exact
          >
            Terms of user and privacy policy
          </router-link>
          <span class="px-3">|</span> Developed by:
          <a href="https://www.svon.si" target="_blank"
            ><img
              :src="require('@/assets/images/svon.png')"
              style="margin-left: 5px; height: 11px; margin-top: -2px;"
          /></a>
          <span class="px-3">|</span> Designed by:
          <a href="mailto:pikaciuha@yahoo.com"
            ><img
              :src="require('@/assets/images/Pika-logo-bel.svg')"
              style="margin-left: 5px; height: 20px; margin-top: -2px;"
          /></a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { db } from "@/main";

export default {
  name: "Footer",
  components: {},
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  mounted() {
    // this.retrievePosts();
    console.log("$route", this.$route);
  },
  methods: {
    async retrievePosts() {
      const _this = this;

      db.collection("posts")
        .orderBy("submittedAt", "desc")
        .limit(15)
        .get()
        .then(function(_docs) {
          _this.posts = _docs.docs.map(doc => doc.data());
        })
        .catch(error => {
          console.log("Error getting categories:", error);
        });
    }
  }
};
</script>
