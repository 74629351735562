var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column min-vh-100",attrs:{"id":"app"}},[(
      _vm.$route.name != 'EN_SignIn' &&
        _vm.$route.name != 'EN_Register' &&
        _vm.$route.name != 'EN_ForgottenPassword'
    )?_c('Header'):_vm._e(),(
      _vm.$route.name == 'EN_SignIn' ||
        _vm.$route.name == 'EN_Register' ||
        _vm.$route.name == 'EN_ForgottenPassword'
    )?_c('HeaderAuth'):_vm._e(),_c('div',{staticClass:"kotha-default-content flex-fill"},[_c('router-view',{key:_vm.$route.fullPath})],1),(
      _vm.$route.name != 'EN_SignIn' &&
        _vm.$route.name != 'EN_Register' &&
        _vm.$route.name != 'EN_ForgottenPassword'
    )?_c('Footer'):_vm._e(),_c('MobileAppMenu'),(_vm.updateExists && _vm.isPWA)?_c('div',{staticClass:"update-dialog"},[_c('div',{staticClass:"update-dialog__content"},[_vm._v(" A new version of the app is now available. ")]),_c('div',{staticClass:"update-dialog__actions"},[_c('button',{staticClass:"btn btn-success mt-2",on:{"click":_vm.refreshApp}},[_vm._v(" Update ")])])]):_vm._e(),_c('cookie-law',{attrs:{"buttonText":'OK'}},[_c('div',{attrs:{"slot":"message"},slot:"message"},[_vm._v(" This website uses cookies to improve the user experience and provide content. "),_c('router-link',{attrs:{"to":{
                    name: 'EN_TermsAndConditions',
                  hash: '#politika-piskotkov'
                  }}},[_vm._v("Več")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }